import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import CopyRight from "../components/copyright"
import Bio from '../components/Bio'
import SocialList from "../components/socialList"
import { rhythm } from '../utils/typography'

class NotFoundPage extends React.Component {
  render() {
  // get props
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')
  const siteDescription = get(this, 'props.data.site.siteMetadata.description')

  return (
  <Layout location={this.props.location}>
  
  <Helmet>
    <meta name="language" content="en" />
    <title>Oops - {siteTitle}</title>
    <meta name="description" content={siteDescription} />
  </Helmet>  

   <header>
     <h1 style={{ color: 'red' }}>Not Found!</h1>
     <p>You hit a route that isn&#39;t real... the horror.</p>
   </header>

    <hr style={{ marginBottom: rhythm(1), }}/>

   <main>
    <article>
      <h2 style={{ color: 'red' }}>...but seriously,</h2>
      <p>It&#39;s not a tradegy. Just use a link to get back to a <Link to="/" title="MacYuppie">real page</Link>.</p>
      <SocialList />
    </article>
   </main>

   <hr style={{ marginBottom: rhythm(1), }}/>

   <Bio />
   <CopyRight />
  </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`